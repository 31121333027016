import api from './ApiRequestHandler';

import { RegistrantI } from '../Datatypes/Datatypes';

/*export interface TicketRequest {
  tripIds: Array<number>;
  passengers: number;
}

export interface TicketPassengerInfo {
  requestId: number;
  passengers: Array<PassengerI>;
}

export const ticketReservation = function (ref: number, passengers: Array<PassengerI>, cb: (d: any) => any) {
  const url = '/api/v1/tickets';
  const data: TicketPassengerInfo = {
    requestId: ref,
    passengers: passengers
  }
  const queryString = `?ref=${ref}`;
//  console.log(`${url}${queryString}`);
  api.put<any, TicketPassengerInfo>(`${url}${queryString}`, data).then(res => cb(res.data));
}*/

export const register = function (registrant: RegistrantI, cb: (d: any) => any) {
//  const url = '/api/v1/registrants';
  const url = '/static.php/mailer/send_order2'
/*  const data: TicketRequest = {
    tripIds: tripIds,
    passengers: passengers
  }*/
//  const queryString = `?from=${tripParameters.from}&to=${tripParameters.to}&departureDate=${tripParameters.departureDate}`;
//  console.log(`${url}${queryString}`);
  api.post<any, RegistrantI>(`${url}`, registrant).then(res => cb(res.data));
}

export default {
  register: register
}
