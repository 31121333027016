import React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';

//import { Watch } from  'react-loader-spinner'
import map from 'lodash/fp/map';
import over from 'lodash/fp/over';
import every from 'lodash/fp/every';
import identity from 'lodash/fp/identity';
import isNull from 'lodash/fp/isNull';
import { parseISO } from 'date-fns/fp'

import HomeAppBar from './HomeAppBar';
import RegistrantInfo from './RegistrantInfo';
import { RegistrantI } from './Datatypes/Datatypes';
//import BookingDetails from '../Dialogs/BookingDetails';
import Registrants from './Services/Registrants';
import { Translations } from './Translations';
import LanguageContext from './LanguageContext';
import tango from './tango.png';

//import Image from '../about-us-pic-10.jpg';*/

interface TermsProps {
  changeLanguage: (l: string) => void;
}

const translations: Translations = {
  en: {
    HEADER: 'Registration failed!!',
    MESSAGE: 'Please try again later'
  },
  el: {
    HEADER: 'Η εγγραφή απέτυχε!!',
    MESSAGE: 'Παρακαλουμε προσπαθήστε ξανά αργότερα'
  }
}

export default function Failure(props: TermsProps) {
  const language = React.useContext(LanguageContext);
  const langTranslations = translations[language.code];

  return (
    <Box
      width="100%"
      position="relative"
      sx={{
        minHeight: '100vh'
      }}
    >
      <HomeAppBar changeLanguage={props.changeLanguage} />
      <Grid
        container
        spacing={2}
        pt={7}
        justifyContent="center"
        alignItems="center"
        direction="column"
        sx={{
          height: {xs: '100%', sm: '100%'}
        }}
      >
        <Grid item xs={12} >
          <Paper sx={{ p: 1, mb: 1, mx: 1, maxWidth: { xs: 450, sm: 600 } }}>
            <Stack
              direction="column"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2
              }}
            >
              <Typography variant="h5">
              {langTranslations.HEADER}
              </Typography>
              <Typography variant="body1">
              {langTranslations.MESSAGE}
              </Typography>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
