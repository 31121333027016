import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import logo from './logo.svg';
import './App.css';

import Home from './Home';
import Terms from './Terms';
import Success from './Success';
import Failure from './Failure';
/*import Trips from './Destinations/Trips';
import Passengers from './Destinations/Passengers';
import Checkout from './Destinations/Checkout';
import Cancel from './Destinations/Cancel';*/
import useLanguage from './UseLanguage';
import { LanguageProvider } from './LanguageContext';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#bfc7c7',
    },
    secondary: {
      main: '#af7200',
    },
  },
});

function App() {
  const [languageCode, setLanguageCode] = React.useState<string>('el');
  const language = useLanguage(languageCode);

  return (
    <ThemeProvider theme={theme}>
      <LanguageProvider value={language}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home changeLanguage={setLanguageCode} />} />
            <Route path="/terms" element={<Terms changeLanguage={setLanguageCode} />} />
            <Route path="/success" element={<Success changeLanguage={setLanguageCode} />} />
            <Route path="/failure" element={<Failure changeLanguage={setLanguageCode} />} />
{/*            <Route path="/trips" element={<Trips />} />
            <Route path="/passengers" element={<Passengers />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/cancel" element={<Cancel />} />
            */}
          </Routes>
        </BrowserRouter>
      </LanguageProvider>
    </ThemeProvider>
  );
}

export default App;
