import React from 'react';
import { createStyles, makeStyles, Theme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';

import logo from './asaok.png';
import LanguageSelectionMenu from './LanguageSelectionMenu';

export interface HomeAppBarProps {
  changeLanguage: (l: string) => void;
  children?: React.ReactNode;
}

const styles = {
  logo: {
    maxHeight: 50,
  },
}

export default function HomeAppBar(props: HomeAppBarProps) {
  const [languageOpen, setLanguageOpen] = React.useState(false);
  const languageAnchorRef = React.useRef<HTMLButtonElement>(null);

  function handleLanguageMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setLanguageOpen(true);
  }

  function handleMenuClose() {
    setLanguageOpen(false);
  }

  function changeLanguage(code: string) {
    props.changeLanguage(code);
    setLanguageOpen(false);
  }

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme: Theme) => theme.zIndex.drawer + 1
        }}
      >
        <Toolbar variant="dense">
          <img src={logo} style={styles.logo}/>
          {props.children}
          <Box sx={{flexGrow: 1}} />
          <IconButton
            aria-label="language selection"
            ref={languageAnchorRef}
            aria-controls="languageMenu"
            aria-haspopup="true"
            onClick={handleLanguageMenuOpen}
            color="inherit"
          >
            <LanguageIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <LanguageSelectionMenu
        open={languageOpen}
        anchorElRef={languageAnchorRef}
        onClose={handleMenuClose}
        changeLanguage={changeLanguage}
      />
    </div>
  );
}
