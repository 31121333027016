import { format } from 'date-fns';
import { el, enGB, Locale } from 'date-fns/locale';
//import elLocale from 'date-fns/locale/el';

const formatDate = function(this: Language, date: Date, formatString: string) {
  const locale = this.locale;

  return format(date, formatString, {locale: locale})
}

interface Language {
  code: string;
  locale: Locale;
  bankCode: string;
  formatDate(date: Date, formatString: string): string;
  SAVE: string;
}

const englishLanguage: Language = {
  code: "en",
  locale: enGB,
  bankCode: "en-US",
  formatDate: formatDate,
  SAVE: "Save"
}

const greekLanguage: Language = {
  code: "el",
  locale: el,
  bankCode: "el-GR",
  formatDate: formatDate,
  SAVE: "Αποθήκευση"
}

interface Languages {
  [key: string]: Language
}

export const supportedLanguages: Languages = {
  en: englishLanguage,
  el: greekLanguage
}

export default function useLanguage(code: string): Language {
  return supportedLanguages[code];
}
