import React, { RefObject } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface LanguageSelectionMenuProps {
  anchorElRef: RefObject<HTMLButtonElement>;
  open: boolean;
  onClose: () => void;
  changeLanguage: (c: string) => void;
}

export default function LanguageSelectionMenu(props: LanguageSelectionMenuProps) {

  return (
    <Menu
      id="languageMenu"
      anchorEl={props.anchorElRef.current}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
//      id={languageMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={props.open}
      onClose={props.onClose}
    >
      <MenuItem onClick={() => props.changeLanguage('el')}>Ελληνικά</MenuItem>
      <MenuItem onClick={() => props.changeLanguage('en')}>English</MenuItem>
    </Menu>
  );
}
