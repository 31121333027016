import React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';

import { Watch } from  'react-loader-spinner'
import map from 'lodash/fp/map';
import over from 'lodash/fp/over';
import every from 'lodash/fp/every';
import identity from 'lodash/fp/identity';
import isNull from 'lodash/fp/isNull';
import { parseISO } from 'date-fns/fp'
import { useNavigate } from 'react-router-dom';

import HomeAppBar from './HomeAppBar';
import RegistrantInfo from './RegistrantInfo';
import { RegistrantI } from './Datatypes/Datatypes';
//import BookingDetails from '../Dialogs/BookingDetails';
import Registrants from './Services/Registrants';
import { Translations } from './Translations';
import LanguageContext from './LanguageContext';
import tango from './tango.png';

//import Image from '../about-us-pic-10.jpg';*/

interface TermsProps {
  changeLanguage: (l: string) => void;
}

const translations: Translations = {
  en: {
    TERMS_1: 'Tango marathon is a closed event - available only for people registered, in the list of participants drawn up by the organizers.',
    TERMS_2: 'Access to the event is granted only to confirmed participants. Without completed registration process, a confirmed place participation is not possible.',
    TERMS_3: 'Registration for the marathon can only be made using this form.',
    TERMS_4: 'Registration will begin at 09.12.2024 Monday at 14:00 (Greek Time).',
    TERMS_5: 'Early bird is valid until 13.12.2024 23:59 (Greek Time).',
    TERMS_6: 'Registration process:',
    TERMS_6A: 'Fill in the registration form.',
    TERMS_6B: 'You will receive a reply within 10 days. (Please check your spam folder), if you don’t have contact us on registration@asaok.gr. If we are not able to accept a registration within 10 days we will add it to the waiting list. We will get in touch again when a vacant spot opens up.',
    TERMS_6C: 'With the acceptance of your registration, payment details will be given allowing 7 days time to issue your payment. We will not send reminders. Your spot may be lost and given away if your payment is not received within 7 days. Payments prior to our notification cannot be accepted.',
    TERMS_6D: 'Please take care of possible transfer fees. Any money transaction fees have to be paid in addition to the full-pass price.',
    TERMS_6E: 'Confirmation of participation can be sent only when the payment for participation has been received in full.',
    TERMS_6F: `Please DO NOT book any tickets until you get our FINAL confirmation e-mail (if you don't receive the email, please, check the SPAM folder or contact us in registration@asaok.gr)`,
    TERMS_7: 'Cancellation policy',
    TERMS_7A: 'There is no claim for refund for booked marathon places.',
    TERMS_7B: 'Your ticket / confirmed participation is personal and therefore cannot be transferred to other persons or be used by different persons for different parts of the event.',
    TERMS_8: 'You are 100% responsible for your accommodation. The Marathon team recommends based on prices and reviews.',
    TERMS_9: 'There is salads ,finger food ,fruits and more on specific hours of the Marathon. We cannot commit to certain food needs/requirements of the participants , but we would be interested to know if you eat any foods or have intolerance to any .',
    TERMS_10: 'The organizers are not responsible for valuables and personal belongings participants left unattended.',
    TERMS_11: 'Food or drink is allowed on the dancing hall of the venue, but we would like you to help us keep the dance and the floor clean.',
    FURTHER: 'If you have any further questions which are not covered above, get in touch with us by writing an e-mail to: tangomarathon@asaok.gr',
    REGISTER: 'Register'
  },
  el: {
    TERMS_1: 'Ο μαραθώνιος Tango είναι κλειστή εκδήλωση - διατίθεται μόνο για άτομα που είναι εγγεγραμμένα, στη λίστα των συμμετεχόντων που έχουν καταρτιστεί από τους διοργανωτές.',
    TERMS_2: 'Η πρόσβαση στην εκδήλωση παρέχεται μόνο σε επιβεβαιωμένους συμμετέχοντες. Χωρίς ολοκληρωμένη διαδικασία εγγραφής, η επιβεβαιωμένη συμμετοχή δεν είναι δυνατή.',
    TERMS_3: 'Η εγγραφή στον μαραθώνιο μπορεί να γίνει μόνο χρησιμοποιώντας αυτήν τη φόρμα.',
    TERMS_4: 'Οι εγγραφές θα ξεκινήσουν στις 09.12.2024 Δευτέρα στις 14:00 (ώρα Ελλάδας).',
    TERMS_5: 'Το Early bird ισχύει έως 13.12.2024 23:59 (ώρα Ελλάδας).',
    TERMS_6: 'Διαδικασία εγγραφής:',
    TERMS_6A: 'Συμπληρώστε τη φόρμα εγγραφής.',
    TERMS_6B: 'Θα λάβετε μια απάντηση εντός 10 ημερών. (Παρακαλούμε ελέγξτε τον φάκελο ανεπιθύμητης αλληλογραφίας σας), εάν δεν έχετε επικοινωνήστε μαζί μας tangoregistration@asaok.gr. Εάν αποδεχθούμε μια εγγραφή εντός 10 ημερών, θα την προσθέσουμε στη λίστα αναμονής. Θα επικοινωνήσουμε ξανά όταν ανοίξει μια κενή θέση.',
    TERMS_6C: 'Με την αποδοχή της εγγραφής σας, θα δοθούν τα στοιχεία πληρωμής επιτρέποντας 7 ημέρες για την έκδοση της πληρωμής σας. Δεν θα στείλουμε υπενθυμίσεις. Η θέση σας μπορεί να χαθεί και να παραχωρηθεί εάν η πληρωμή σας δεν ληφθεί εντός 7 ημερών. Δεν γίνονται δεκτές πληρωμές πριν από την ειδοποίησή μας.',
    TERMS_6D: 'Φροντίστε για πιθανές χρεώσεις μεταβίβασης. Πρέπει να καταβληθούν χρεώσεις συναλλαγής οποιουδήποτε χρήματος επιπλέον της τιμής πλήρους πρόσβασης.',
    TERMS_6E: 'Η επιβεβαίωση συμμετοχής μπορεί να αποσταλεί μόνο όταν η πληρωμή της συμμετοχής έχει ληφθεί πλήρως.',
    TERMS_6F: `Παρακαλούμε ΜΗΝ ΚΡΑΤΗΣΕΤΕ εισιτήρια μέχρι να λάβετε το ΤΕΛΙΚΟ μας email επιβεβαίωσης (αν δεν λάβετε το email, ελέγξτε τον φάκελο SPAM ή επικοινωνήστε μαζί μας στο register@asaok.gr)`,
    TERMS_7: 'Πολιτική ακύρωσης',
    TERMS_7A: 'Δεν υπάρχει αξίωση για επιστροφή χρημάτων για κρατήσεις μαραθωνίου.',
    TERMS_7B: 'Το εισιτήριό σας / η επιβεβαιωμένη συμμετοχή σας είναι προσωπική και επομένως δεν μπορεί να μεταβιβαστεί σε άλλα άτομα ή να χρησιμοποιηθεί από διαφορετικά άτομα για διαφορετικά μέρη της εκδήλωσης.',
    TERMS_8: 'Είστε 100% υπεύθυνοι για τη διαμονή σας. Η ομάδα του Marathon προτείνει με βάση τις τιμές και τις κριτικές.',
    TERMS_9: 'Υπάρχουν σαλάτες, finger food, φρούτα και περισσότερες συγκεκριμένες ώρες του Μαραθωνίου. Δεν μπορούμε να δεσμευτούμε για ορισμένες τροφικές ανάγκες/απαιτήσεις των συμμετεχόντων, αλλά θα μας ενδιέφερε να μάθουμε εάν τρώτε κάποια τροφή ή έχετε δυσανεξία σε κάποια.',
    TERMS_10: 'Οι διοργανωτές δεν φέρουν ευθύνη για τιμαλφή και προσωπικά αντικείμενα που οι συμμετέχοντες αφήνονται χωρίς επίβλεψη.',
    TERMS_11: 'Επιτρέπεται φαγητό ή ποτό στην αίθουσα χορού του χώρου, αλλά θα θέλαμε να μας βοηθήσετε να διατηρήσουμε τον χορό και το πάτωμα καθαρό.',
    FURTHER: 'Εάν έχετε περαιτέρω ερωτήσεις που δεν καλύπτονται παραπάνω, επικοινωνήστε μαζί μας γράφοντας ένα e-mail στη διεύθυνση: tangomarathon@asaok.gr',
    REGISTER: 'Εγγραφή'
  }
}

function getLocalStorageItem(item: string): string {
  const stored = localStorage.getItem(item);
  const returnVal = isNull(stored) ? "" : stored as string;

  return returnVal;
}

export default function Terms(props: TermsProps) {
/*  const [allPorts, setAllPorts] = React.useState<Array<any>>([]);
  const [depPort, setDepPort] = React.useState<string>('');
  const [arrPort, setArrPort] = React.useState<string>('');
  const [open, setOpen] = React.useState(false);
  const [showValidation, setShowValidation] = React.useState<boolean>(false);*/
  const language = React.useContext(LanguageContext);
  const langTranslations = translations[language.code];
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const register = () => {
    const payment = getLocalStorageItem('payment');

    const registrant: RegistrantI = {
      fullname: getLocalStorageItem('fullName'),
      city: getLocalStorageItem('city'),
      country: getLocalStorageItem('country'),
      dateOfBirth: getLocalStorageItem('dob'),
      phone:  getLocalStorageItem('phone'),
      email: getLocalStorageItem('email'),
      role: getLocalStorageItem('role'),
      coupleFullname: getLocalStorageItem('coupleFullName'),
      paymentMethod: payment == 'other' ? getLocalStorageItem('paymentMethod') : payment
    }


    if (process.env.NODE_ENV !== 'production') {
      navigate('/success');
    } else {
      setLoading(true);
      Registrants.register(registrant, (data: any) => {
        console.log(data);
        if (data == 'SUCCESS') {
          navigate('/success');
        } else {
          navigate('/failure');
        }
      });
    }
  }

  const Loading = function () {
    return (
      <Watch
        height="40"
        width="40"
        radius="48"
        color="#af7200"
        ariaLabel="watch-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    )
  }
/*  React.useEffect(() => {
    Ports.getPorts(setAllPorts);
  }, []);

  const handleDepartureChange = (event: SelectChangeEvent) => {
    setDepPort(event.target.value as string);
  };

  const handleArrivalChange = (event: SelectChangeEvent) => {
    setArrPort(event.target.value as string);
  };

  const handleClickOpen = () => {
    const valid = every(identity, validate());

    if (!valid) setShowValidation(true)
    else setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validate = over([
    () => depPort !== '',
    () => arrPort !== '',
    () => depPort !== '' && depPort !== arrPort
  ]);

  const portItems = map((port: any) => {
    return (
      <MenuItem key={port.id} value={port.id}>{port.portname}</MenuItem>
    );
  })(allPorts);

  const termsLink = `${language.code}/terms_of_use.html`;
  const privacyLink = `${language.code}/privacy_policy.html`;
  const cookiesLink = `${language.code}/cookies_policy.html`;

  const DeparturePortSelect = function () {
    return (
      <FormControl variant="standard" fullWidth>
        <InputLabel id="departure-port-select-label">{langTranslations.FROM}</InputLabel>
        <Select
          labelId="departure-port-select-label"
          id="departure-port-select"
          value={depPort}
          label={langTranslations.FROM}
          onChange={handleDepartureChange}
        >
          {portItems}
        </Select>
      </FormControl>
    );
  }

  const ArrivalPortSelect = function () {
    return (
      <FormControl variant="standard" fullWidth>
        <InputLabel id="arrival-port-select-label">{langTranslations.TO}</InputLabel>
        <Select
          labelId="arrival-port-select-label"
          id="arrival-port-select"
          value={arrPort}
          label={langTranslations.TO}
          onChange={handleArrivalChange}
        >
          {portItems}
        </Select>
      </FormControl>
    );
  }
*/
  return (
    <div>
      {loading && <Loading/>}
      <Box
        width="100%"
        position="relative"
        sx={{
          minHeight: '100vh'
        }}
      >
        <HomeAppBar changeLanguage={props.changeLanguage} />
        <Grid
          container
          spacing={2}
          pt={7}
          justifyContent="center"
          alignItems="center"
          direction="column"
          sx={{
            height: {xs: '100%', sm: '100%'}
          }}
        >
          <Grid item xs={12} >
            <Paper sx={{ p: 1, mb: 1, mx: 1, maxWidth: { xs: 450, sm: 600 } }}>
              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2
                }}
              >
                <Typography variant="h5">
                Tango Marathon
                </Typography>
                <Box
                  component="img"
                  sx={{
                    maxHeight: { xs: 43, md: 43 },
                    maxWidth: { xs: 100, md: 100 },
                  }}
                  alt="The house from the offer."
                  src={tango}
                />
              </Stack>
              <List sx={{ listStyle: "decimal", pl: 4 }} dense={true}>
                <ListItem sx={{ display: "list-item" }} disablePadding >
                  <ListItemText primary={langTranslations.TERMS_1} />
                </ListItem>
                <ListItem sx={{ display: "list-item" }} disablePadding >
                  <ListItemText primary={langTranslations.TERMS_2} />
                </ListItem>
                <ListItem sx={{ display: "list-item" }} disablePadding >
                  <ListItemText primary={langTranslations.TERMS_3} />
                </ListItem>
                <ListItem sx={{ display: "list-item" }} disablePadding >
                  <ListItemText primary={langTranslations.TERMS_4} />
                </ListItem>
                <ListItem sx={{ display: "list-item" }} disablePadding >
                  <ListItemText primary={langTranslations.TERMS_5} />
                </ListItem>
                <ListItem sx={{ display: "list-item" }} disablePadding >
                  <ListItemText primary={langTranslations.TERMS_6} />
                  <List sx={{ listStyle: "lower-alpha", pl: 2 }} dense={true}>
                    <ListItem sx={{ display: "list-item" }} disablePadding >
                      <ListItemText primary={langTranslations.TERMS_6A} />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }} disablePadding >
                      <ListItemText primary={langTranslations.TERMS_6B} />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }} disablePadding >
                      <ListItemText primary={langTranslations.TERMS_6C} />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }} disablePadding >
                      <ListItemText primary={langTranslations.TERMS_6D} />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }} disablePadding >
                      <ListItemText primary={langTranslations.TERMS_6E} />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }} disablePadding >
                      <ListItemText primary={langTranslations.TERMS_6F} />
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem sx={{ display: "list-item" }} disablePadding >
                  <ListItemText primary={langTranslations.TERMS_7} />
                  <List sx={{ listStyle: "lower-alpha", pl: 2 }} dense={true}>
                    <ListItem sx={{ display: "list-item" }} disablePadding >
                      <ListItemText primary={langTranslations.TERMS_7A} />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }} disablePadding >
                      <ListItemText primary={langTranslations.TERMS_7B} />
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem sx={{ display: "list-item" }} disablePadding >
                  <ListItemText primary={langTranslations.TERMS_8} />
                </ListItem>
                <ListItem sx={{ display: "list-item" }} disablePadding >
                  <ListItemText primary={langTranslations.TERMS_9} />
                </ListItem>
                <ListItem sx={{ display: "list-item" }} disablePadding >
                  <ListItemText primary={langTranslations.TERMS_10} />
                </ListItem>
                <ListItem sx={{ display: "list-item" }} disablePadding >
                  <ListItemText primary={langTranslations.TERMS_11} />
                </ListItem>
              </List>
              {!loading &&
              <Button variant="contained" color="secondary" onClick={register} >
                {langTranslations.REGISTER}
              </Button>
              }
              {loading && <Loading />}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
