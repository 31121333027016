import axios from 'axios';
import { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';

declare global {
  interface Window { SERVER_DATA: any; }
}
//mport { navigate } from "@reach/router";
//import { LocationState } from "history";

/*interface MyLocationState extends LocationState {
  returnLoc: string;
}*/

const axiosInstance = axios.create({
//  baseURL: process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_SERVER : window.SERVER_DATA.REACT_APP_API_SERVER,
  baseURL: 'https://registration.asaok.gr',
  withCredentials: true
});

const api = {
  axios: axiosInstance
}

const get = function <T>(url: string): AxiosPromise<T> {
  return axiosInstance.get<T>(url);
}

const post = function <T, D>(url: string, data: D): AxiosPromise<T> {
  return axiosInstance.post<T, AxiosResponse<T>, D>(url, data);
}

const put = function <T, D>(url: string, data: D): AxiosPromise<T> {
  return axiosInstance.put<T, AxiosResponse<T>, D>(url, data);
}
/*const getN = function <T>(url: string, cb: (d: any) => any, returnLoc: string): any {
  axiosInstance.get<T>(url).then(res => {
    if (typeof res.data === 'string' && res.data === 'LOGIN') {
      navigate('/login', { state: { returnLoc: returnLoc } });
    } else {
      cb(res.data);
    }
  })
}

const post = function <T>(url: string, data: any, cb: (d: any) => any, returnLoc: string, config?: AxiosRequestConfig): any {
  axiosInstance.post<T>(url, data, config).then(res => {
    if (typeof res.data === 'string' && res.data === 'LOGIN') {
      navigate('/login', { state: { returnLoc: returnLoc } });
    } else {
      cb(res.data);
    }
  })
}*/

export default {
  get: get,
  post: post,
  put: put
//  getN: getN,
//  post: post
}
