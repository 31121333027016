import React from 'react'

import { supportedLanguages } from './UseLanguage';

const initialLanguage = supportedLanguages.el;
const LanguageContext = React.createContext(initialLanguage);

export const LanguageProvider = LanguageContext.Provider
export const LanguageConsumer = LanguageContext.Consumer
export default LanguageContext
