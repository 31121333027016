import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
//import { Watch } from  'react-loader-spinner'
import map from 'lodash/fp/map';
import over from 'lodash/fp/over';
import every from 'lodash/fp/every';
import identity from 'lodash/fp/identity';

import HomeAppBar from './HomeAppBar';
import RegistrantInfo from './RegistrantInfo';

/*import BookingDetails from '../Dialogs/BookingDetails';
import Ports from '../Services/Ports';*/
import { Translations } from './Translations';
import LanguageContext from './LanguageContext';
//import Image from '../about-us-pic-10.jpg';*/

interface HomeProps {
  changeLanguage: (l: string) => void;
}

const translations: Translations = {
  en: {
    FROM: 'From',
    TO: 'To',
    BOOK: 'Book a trip',
    SEARCH: 'Search',
    DEPARTURE: 'Departure',
    ARRIVAL: 'Destination',
    NO_DEP_PORT: 'Please select departure port',
    NO_ARR_PORT: 'Please select arrival port',
    SAME_DEP_ARR: 'Departure should be different from arrival',
    ERRORS: 'Error',
    TERMS_OF_USE: 'Terms of Use',
    PRIVACY_POLICY: 'Privacy Policy and Data Protection',
    COOKIES_POLICY: 'Cookies Policy'
  },
  el: {
    FROM: 'Από',
    TO: 'Προς',
    BOOK: 'Κάντε κράτηση',
    SEARCH: 'Αναζήτηση',
    DEPARTURE: 'Αναχώρηση',
    ARRIVAL: 'Προορισμός',
    NO_DEP_PORT: 'Επιλέξτε λιμάνι αναχώρησης',
    NO_ARR_PORT: 'Επιλέξτε λιμάνι προορισμού',
    SAME_DEP_ARR: 'Επιλεξτε διαφορετικό λιμάνι αναχώρησης και προορισμού',
    ERRORS: 'Λάθος',
    TERMS_OF_USE: 'Όροι Χρήσης',
    PRIVACY_POLICY: 'Πολιτική Απορρήτου και Προστασία Δεδομένων',
    COOKIES_POLICY: 'Πολιτική Cookies'
  }
}

export default function Home(props: HomeProps) {
/*  const [allPorts, setAllPorts] = React.useState<Array<any>>([]);
  const [depPort, setDepPort] = React.useState<string>('');
  const [arrPort, setArrPort] = React.useState<string>('');
  const [open, setOpen] = React.useState(false);
  const [showValidation, setShowValidation] = React.useState<boolean>(false);*/
  const language = React.useContext(LanguageContext);
  const langTranslations = translations[language.code];

/*  React.useEffect(() => {
    Ports.getPorts(setAllPorts);
  }, []);

  const handleDepartureChange = (event: SelectChangeEvent) => {
    setDepPort(event.target.value as string);
  };

  const handleArrivalChange = (event: SelectChangeEvent) => {
    setArrPort(event.target.value as string);
  };

  const handleClickOpen = () => {
    const valid = every(identity, validate());

    if (!valid) setShowValidation(true)
    else setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validate = over([
    () => depPort !== '',
    () => arrPort !== '',
    () => depPort !== '' && depPort !== arrPort
  ]);

  const portItems = map((port: any) => {
    return (
      <MenuItem key={port.id} value={port.id}>{port.portname}</MenuItem>
    );
  })(allPorts);

  const termsLink = `${language.code}/terms_of_use.html`;
  const privacyLink = `${language.code}/privacy_policy.html`;
  const cookiesLink = `${language.code}/cookies_policy.html`;

  const DeparturePortSelect = function () {
    return (
      <FormControl variant="standard" fullWidth>
        <InputLabel id="departure-port-select-label">{langTranslations.FROM}</InputLabel>
        <Select
          labelId="departure-port-select-label"
          id="departure-port-select"
          value={depPort}
          label={langTranslations.FROM}
          onChange={handleDepartureChange}
        >
          {portItems}
        </Select>
      </FormControl>
    );
  }

  const ArrivalPortSelect = function () {
    return (
      <FormControl variant="standard" fullWidth>
        <InputLabel id="arrival-port-select-label">{langTranslations.TO}</InputLabel>
        <Select
          labelId="arrival-port-select-label"
          id="arrival-port-select"
          value={arrPort}
          label={langTranslations.TO}
          onChange={handleArrivalChange}
        >
          {portItems}
        </Select>
      </FormControl>
    );
  }
*/
  return (
    <Box
      width="100%"
      position="relative"
      sx={{
        minHeight: '100vh'
      }}
    >
      <HomeAppBar changeLanguage={props.changeLanguage} />
      <Stack
        pt={7}
        justifyContent="center"
        alignItems="center"
        direction="column"
        sx={{
          height: '100%'
        }}
      >
        <RegistrantInfo />
      </Stack>
    </Box>
  );
}
